export {
  setValuesInLocalStorage,
  setValueInLocalStorage,
  getValueFromLocalStorage,
  removeKeyFromLocaleStorage,
  checkIfKeyExist,
};

/**
 * Get a array of query params, and set it in local storage, only  if the values of the array are in the current url
 *
 * @param {Array} queryParams - array of query params e.x. const queryParams = ['param1', 'param2']
 */
const setValuesInLocalStorage = (queryParams) => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};
  for (const [key, value] of urlParams) {
    params[key] = value;
  }

  for (const key in params) {
    if (params.hasOwnProperty(key) && queryParams.includes(key)) {
      localStorage.setItem(key, params[key]);
    }
  }
};

/**
 * set value with key in local storage
 *
 * @param {String} key
 * @param {String} value
 */
const setValueInLocalStorage = (key, value) => {
  if (localStorage.getItem(key) === null) {
    localStorage.setItem(key, value);
  }
};

/**
 * Get a value of specific key from local storage
 *
 * @param {String} key -
 */
const getValueFromLocalStorage = (key) => {
  const valueFromLocalStorage = localStorage.getItem(key);
  return valueFromLocalStorage !== null ? valueFromLocalStorage : null;
};

/**
 * Remove specific key-value from local storage
 *
 * @param {String} key -
 */
const removeKeyFromLocaleStorage = (key) => {
  const valueFromLocalStorage = localStorage.getItem(key);
  if (valueFromLocalStorage !== null) {
    localStorage.removeItem(key);
  }
};

/**
 * Check if specific key exist in local storage
 *
 * @param {String} key
 * @returns {boolean}
 */
const checkIfKeyExist = (key) => {
  return localStorage.getItem(key) !== null;
};
